<template>
  <section class="policy-list">
    <div class="show-table-maxscreen">
      <div
        class="flex-justify flex flex-content">
        <div
          class="flex title-tip">
          <div class="flex-cloumn">
            <span> All Currently Listed </span>
            <span></span>
          </div>
          <div class="flex-cloumn">
            <span>Total Policies</span>
            <span class="number">{{summary.policy_num}}</span>
          </div>
          <div class="flex-cloumn">
            <span>Total Premium </span>
            <span class="number">{{summary.total_premium|formatMoney(2,true)}}</span>
          </div>
          <div class="flex-cloumn">
            <span>Total Commission for R&B </span>
            <span class="number">{{summary.owner_commission_vl|formatMoney(2,true)}}</span>
          </div>
          <div class="flex-cloumn">
            <span>Total Referrer fee</span>
            <span class="number">{{summary.referrer_commission_vl|formatMoney(2,true)}}</span>
          </div>
        </div>
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        :header-row-class-name="'table-header'"
        style="width: 100%"
        :row-key="getRowId"
        @selection-change="handleSelectionChange"
      >
        <div >
          <el-table-column width="10">
          </el-table-column>
          <el-table-column
            width="150"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Issue Date</span>
                <v-date-picker
                  v-model="issueDate"
                  type="daterange"
                  :rangeSeparator="'-'"
                  :placeholder="'Date'"
                  @change="handleChange"
                ></v-date-picker>
              </div>
            </template>
            <template slot-scope="scope">
              <span>{{showMonth(scope.row.issue_date)}}</span>
            </template>
          </el-table-column>
          <el-table-column
            width="150"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Effective Date</span>
                <v-date-picker
                  v-model="effectiveDate"
                  type="daterange"
                  :rangeSeparator="'-'"
                  :placeholder="'Date'"
                  @change="handleChange"
                ></v-date-picker>
              </div>
            </template>
            <template slot-scope="scope">
              <span>{{showMonth(scope.row.effective_date)}}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="type==1"
            width="150"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Expiry Date</span>
                <v-date-picker
                  v-model="expiryDate"
                  type="daterange"
                  :rangeSeparator="'-'"
                  :placeholder="'Date'"></v-date-picker>
              </div>
            </template>
            <template slot-scope="scope">
              <span>{{showMonth(scope.row.expiry_date)}}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="type==2"
            width="160"
            :show-overflow-tooltip="true"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Insurer</span>
                <v-select
                  v-model="company_id"
                  :list="companyList"
                  @change="handleChange"></v-select>
              </div>
            </template>
            <template slot-scope="scope">
              <div class="flex flex-logo">
                <span
                  v-if="showOtherInsurer(scope.row.company_id)"
                  class="company-logo">
                  <img
                    :src="getOptionData('insureSVGOptions',scope.row.company_id).logo"
                    alt="company" />
                </span>
                <span  class="text-over-flow">{{getOptionName('globalOptions.company',scope.row.company_id)}}</span>

              </div>
            </template>
          </el-table-column>
          <el-table-column
            width="200"
            :show-overflow-tooltip="true"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Product</span>
                <v-select
                  v-model="product_id"
                  :list="productList"
                  @change="handleChange"></v-select>
              </div>
            </template>
            <template slot-scope="scope">
              <span  class="text-over-flow">{{ getOptionName('productTypeOptions',scope.row.product_id)}}</span>
            </template>

          </el-table-column>
          <el-table-column
            width="220"
            :show-overflow-tooltip="true"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Policyholder</span>
                <v-input
                  v-model="policy_holder"
                  placeholder="Search"
                  @change="handleChange"></v-input>
              </div>
            </template>
            <template slot-scope="scope">
              <span>{{scope.row.policy_holder}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="policy_no"
            width="220"
            :show-overflow-tooltip="true"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Policy No.</span>
                <v-input
                  v-model="policy_no"
                  placeholder="Search"
                  @change="handleChange"></v-input>
              </div>
            </template>
            <template slot-scope="scope">
              <span
                class="link hand flex flex-aligin"
                @click="handlerPolicy(scope.row)">
                <font-awesome-icon icon="link"></font-awesome-icon>
                <span>{{ scope.row.policy_no||'-'}}</span>
              </span>
            </template>
          </el-table-column>


          <el-table-column
            prop="status"
            width="120"
            :show-overflow-tooltip="true"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Gross Premium</span>
                <!--                <v-input placeholder="Search"></v-input>-->
              </div>
            </template>
            <template slot-scope="scope">
              <span>{{ scope.row.gross_premium|formatMoney(2,true)}}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="type==2"
            prop="status"
            width="120"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Premium Received</span>
                <!--                <v-input placeholder="Search"></v-input>-->
              </div>
            </template>
            <template slot-scope="scope">
              <span>{{ scope.row.amount_received|formatMoney(2,true)}}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="type==1"
            prop="status"
            width="120"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Net Premium</span>
                <!--                <v-input placeholder="Search"></v-input>-->
              </div>
            </template>
            <template slot-scope="scope">
              <span>{{ scope.row.insurer_net_vl|formatMoney(2,true)}}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="type==2"
            prop="status"
            width="180"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Comm. to Referrer</span>
                <!--                <v-input placeholder="Search"></v-input>-->
              </div>
            </template>
            <template slot-scope="scope">
              <span class="color-red">{{ scope.row.referrer_commission_vl|formatMoney(2,true)}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            width="180"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Status</span>
                <v-select
                  v-model="policy_status"
                  :list="policy_status_list"
                  @change="handleChange"></v-select>
              </div>
            </template>
            <template  slot-scope="scope">
              <v-button
                :class="getOptionName('buttonColorClassOption',scope.row.policy_status)"
                disabled
                plain>{{getOptionName('statusOptions',scope.row.policy_status)}}</v-button>
            </template>
          </el-table-column>


        </div>
      </el-table>
    </div>
    <div class="show-table-minscreen">
      <div class="search-box ">
        <div
          class="search-title theme-second-blue"
          @click="show = !show">
          <div class="flex flex-center">
            <font-awesome-icon icon="filter"></font-awesome-icon>
            <span class="is-bold">Filter & Sort</span>
          </div>
          <font-awesome-icon :icon="show?'sort-up':'sort-down'"></font-awesome-icon>
        </div>
        <el-collapse-transition>
          <div
            v-show="show"
          >
            <div  class="margintop">
              <div>
                <el-row>
                  <v-row-col>
                    <span class="is-bold">Issue Date</span>
                    <v-date-picker
                      v-model="issueDate"
                      type="daterange"
                      :isPicker="true"
                      :rangeSeparator="'-'"
                      :placeholder="'Date'"
                      @change="handleChange"></v-date-picker>
                  </v-row-col>

                  <v-row-col>
                    <span class="is-bold">Effective Date</span>
                    <v-date-picker
                      v-model="effectiveDate"
                      type="daterange"
                      :isPicker="true"
                      :rangeSeparator="'-'"
                      :placeholder="'Date'"
                      @change="handleChange"></v-date-picker>
                  </v-row-col>
                  <v-row-col v-if="type==1">
                    <span class="is-bold">Expiry Date</span>
                    <v-date-picker
                      type="daterange"
                      :isPicker="true"
                      :rangeSeparator="'-'"
                      :placeholder="'Date'"
                    ></v-date-picker>
                  </v-row-col>
                  <v-row-col v-if="type==2">
                    <span class="is-bold">Insurer</span>
                    <v-select
                      v-model='company_id'
                      :list="companyList"
                      @change="handleChange"></v-select>
                  </v-row-col>
                  <v-row-col>
                    <span class="is-bold">Product</span>
                    <v-select
                      v-model="product_id"
                      :list="productList"
                      @change="handleChange"></v-select>
                  </v-row-col>
                  <v-row-col>
                    <span class="is-bold">Policyholder</span>
                    <v-input
                      v-model="policy_holder"
                      placeholder="Search"
                      @change="handleChange"></v-input>
                  </v-row-col>

                  <v-row-col>
                    <span class="is-bold">Policy No.</span>
                    <v-input
                      v-model="policy_no"
                      placeholder="Search"
                      @change="handleChange"></v-input>
                  </v-row-col>

                  <!--                  <v-row-col >-->
                  <!--                    <span class="is-bold">Gross Premium</span>-->
                  <!--                    <v-input placeholder="Search"></v-input>-->
                  <!--                  </v-row-col>-->
                  <!--                  <v-row-col  v-if="type==2">-->
                  <!--                    <span class="is-bold">Premium Received</span>-->
                  <!--                    <v-input placeholder="Search"></v-input>-->
                  <!--                  </v-row-col>-->
                  <!--                  <v-row-col  v-if="type==2">-->
                  <!--                    <span class="is-bold">Comm. to Referrer</span>-->
                  <!--                    <v-input placeholder="Search"></v-input>-->
                  <!--                  </v-row-col>-->
                  <!--                  <v-row-col  v-if="type==1">-->
                  <!--                    <span class="is-bold">Net Premium</span>-->
                  <!--                    <v-input placeholder="Search"></v-input>-->
                  <!--                  </v-row-col>-->
                  <v-row-col >
                    <span class="is-bold">Status</span>
                    <v-select
                      v-model="policy_status"
                      :list="policy_status_list"
                      placeholder="Search"
                      @change="handleChange"></v-select>
                  </v-row-col>
                </el-row>
              </div>
            </div>
          </div>
        </el-collapse-transition>
      </div>

      <div
        v-for="(item,index) in tableData"
        :key="index"
        class="table">
        <div
        >
          <div
            class="tr">
            <span>Issue Date</span>
            <span>{{showMonth(item.issue_date)}}</span>
          </div>

          <div
            class="tr">
            <span>Effective Date</span>
            <span>{{showMonth(item.effective_date)}}</span>
          </div>
          <div
            v-if="type==1"
            class="tr">
            <span>Expiry Date</span>
            <span>{{showMonth(item.expiry_date)}}</span>
          </div>
          <div
            v-if="type==2"
            class="tr">
            <span>Insurer</span>
            <span>{{item.insurer}}</span>
          </div>
          <div
            class="tr">
            <span>Product</span>
            <span>{{getOptionName('productTypeOptions',item.product_id)}}</span>
          </div>
          <div
            class="tr">
            <span>Policyholder</span>
            <span>{{item.policy_holder}}</span>
          </div>
          <div
            class="tr">
            <span>Policy No.</span>
            <span
              class="link"
              @click="handlerPolicy(item.order_no)">{{item.policy_no}}</span>
          </div>
          <div
            class="tr">
            <span>Gross Premium</span>
            <span>{{item.gross_premium|formatMoney(2,true)}}</span>
          </div>
          <div
            v-if="type==2"
            class="tr">
            <span>Premium Received</span>
            <span>{{ item.amount_received|formatMoney(2,true)}}</span>
          </div>
          <div
            v-if="type==2"
            class="tr">
            <span>Comm. to Referrer</span>
            <span>{{item.referrer_commission_vl|formatMoney(2,true)}}</span>
          </div>
          <div
            v-if="type==1"
            class="tr">
            <span>Net Premium</span>
            <span>{{item.insurer_net_vl|formatMoney(2,true)}}</span>
          </div>
          <div
            class="tr">
            <span>Status</span>
            <span>   <v-button
              :class="getOptionName('buttonColorClassOption',item.policy_status)"
              disabled
              plain>{{getOptionName('statusOptions',item.policy_status)}}</v-button></span>

          </div>
        </div>
      </div>
    </div>
    <v-pagination
      :total="pageConfig.total"
      :current="pageConfig.currentPage"
      @handle-current-change="currentChange"
      @handle-size-change="handleSizeChange"/>
    <mark-dialog
      ref="mark"
      @handlerSettle="handlerSettle"></mark-dialog>
  </section>
</template>

<script>
import markDialog from '../../components/dialog/markDialog'
import MixinFormat from '@/mixins/format'
import MixinOptions from '@/mixins/options'
import {setInsurerSettle,getInsurerRecord} from '@/api/insurer'
import {setReferrerSettle,getReferrerRecord,getReferrerPending} from '@api/referrer'
import {mapActions} from 'vuex'

export default {
  name: 'policiesSold',
  components:{
    markDialog
  },
  mixins: [MixinOptions,MixinFormat],
  props:{
    isSelect:{
      type:Boolean,
      default:true
    },
    type:{
      type:Number,
      default:1 //1 insurer 2 referral
    },
    activeName:{
      type:[String,Number],
      default:1 //1 polices 2 statement
    },
    summary:{
      type:Object,
      default:()=>({})
    }
  },
  data(){
    return {
      show:false,
      date:'',
      issueDate:'',
      effectiveDate:'',
      expiryDate:'',
      company_id:'',
      product_id:'',
      policy_holder:'',
      policy_no:'',
      policy_status:'',
      pageConfig: {
        //  分頁配置
        currentPage: 1,
        total: 10,
        size:20
      },
      multipleSelection: [],
      tableData:[]
    }
  },
  computed:{
    isAble(){
      return this.multipleSelection.length<=0
    },
    idResultList() {
      const orderList = this.multipleSelection.reduce((result, item) => {
        result.push(item.order_no)
        return result
      }, [])
      return orderList
    },
    policy_status_list(){
      let arr = [{name:'All',id:''}]
      return arr.concat(this.globalOptions.policy_status)
    },
    companyList(){
      let arr = [{name:'All',id:''}]
      return arr.concat(this.globalOptions.company)
    },
    productList(){
      let arr = [{name:'All',id:''}]
      return arr.concat(this.globalOptions.product)
    }
  },
  created(){
    this.getList()
  },
  methods: {
    ...mapActions('statement', ['setStatement','setStatementParams']),
    handlerSelect(list) {
      list.forEach(item => {
        this.$nextTick(() => {
          this.$refs.multipleTable.toggleRowSelection(item, true)
        })
      })
    },
    handlerPolicy({order_no,policy_type}) {
      // this.$router.push({
      //   path: '/quotation/premium/info',
      //   query: {
      //     order_no
      //   }
      // })
      this.$router.push({
        name: 'info',
        query:{
          order_no,
          isEndorsement:policy_type==203
        }
      })
    },
    handlerMark() {
      this.$refs.mark.showReferDialog()
    },
    handlerSettle(data) {
      let params = {...data, order_no: this.idResultList,}
      if (this.type == 1)
        params.company_id =this.$route.params.companyId,
        this.network().setInsurerSettle(params)
      else {
        params.referral_id =this.$route.params.id,
        this.network().setReferrerSettle(params)
      }
    },
    // this.$emit('handlerSettle',{...data,order_no:this.idResultList})
    handlerClearSelection() {
      this.$refs.multipleTable.clearSelection()
    },
    getRowId (row) {
      return row.order_no
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    selectable(row, index) {
      return row.is_settled == false
    },
    showOtherInsurer(company_id) {
      let arr = [2, 3, 4, 5]
      return arr.includes(company_id)
    },

    getList() {
      if (this.type == 1) {
        this.network().getInsurerRecord()
      } else if (this.type == 2) {
        this.network().getReferrerPending()
      }
    },
    currentChange(page) {
      this.pageConfig.currentPage = page
      this.getList()
    },
    handleSizeChange(size) {
      this.pageConfig.size = size
      this.getList()
    },
    handleChange(){
      this.pageConfig.currentPage = 1
      this.getList()
    },
    network() {
      return {
        setReferrerSettle: async (params) => {
          const {data} = await setReferrerSettle(params)
          this.setStatement(params)
          let dataParams={
            month:data.month,
            settlement:data.settlement_id,
            referral:this.$route.params.id,
          }
          this.setStatementParams(dataParams)
          this.$router.push({
            path:'/member/statement/list',
            query:{
              type:this.type,
              // ...dataParams
            }
          })
        },
        getReferrerPending: async (params) => {
          const {data} = await getReferrerPending({
            referral_id: this.$route.params.id,
            size: this.pageConfig.size,
            page: this.pageConfig.currentPage,
            issue_date_start:this.issueDate?this.issueDate[0]: '',
            issue_date_end:this.issueDate?this.issueDate[1]: '',
            effective_date_start:this.effectiveDate?this.effectiveDate[0]: '',
            effective_date_end:this.effectiveDate?this.effectiveDate[1]: '',
            policy_status:this.policy_status,
            policy_no:this.policy_no,
            policy_holder:this.policy_holder,
            product_id:this.product_id,
            company_id:this.company_id
          })
          let {meta} = data
          this.tableData = data.data
          this.pageConfig.total = meta.total
        },
        setInsurerSettle: async (params) => {
          const {data} = await setInsurerSettle(params)
          this.setStatement(params)
          //insurer
          let dataParams = {
            month:data.month,
            settlement:data.settlement_id,
            company:this.$route.params.companyId,
          }
          this.setStatementParams(dataParams)
          this.$router.push({
            path:'/member/statement/list',
            query:{
              type:this.type,
              // ...dataParams
            }
          })
          // this.network().getInsurerRecord()
        },
        getInsurerRecord: async (params) => {
          const {data} = await getInsurerRecord({
            company_id: this.$route.params.companyId,
            size: this.pageConfig.size,
            page: this.pageConfig.currentPage,
            expiry_date_start:this.expiryDate?this.expiryDate[0]: '',
            expiry_date_end:this.expiryDate?this.expiryDate[1]: '',
            issue_date_start:this.issueDate?this.issueDate[0]: '',
            issue_date_end:this.issueDate?this.issueDate[1]: '',
            effective_date_start:this.effectiveDate?this.effectiveDate[0]: '',
            effective_date_end:this.effectiveDate?this.effectiveDate[1]: '',
            policy_status:this.policy_status,
            policy_no:this.policy_no,
            policy_holder:this.policy_holder,
            product_id:this.product_id,
          })
          let {meta} = data
          this.tableData = data.data
          this.pageConfig.total = meta.total
        },
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import '../../../assets/css/policyList';
.flex-content{
  margin-bottom: 20px;
  .title-tip{
    font-size: 16px;
    font-weight: 600;
    .flex-cloumn:first-child{
      color: #000;
    }
    .flex-cloumn{
      margin-right: 30px;
      display: flex;
      flex-direction: column;
      color: #4A5568;
      justify-content: space-between;
      .number{
        font-size: 28px;
        color: #718096;
      }
    }
  }
  .flex-button{
    align-self: flex-end;
  }
}
.color-red{
  color: #ee0a24;
}
.flex-logo{
  align-items: center;
  .company-logo{
    width: 30px;
    height: 20px;
    display: flex;
    img{
      width: 60%;
      display: block;
    }
  }
}
::v-deep .table-slot-header{
  height: 71px!important;
}
</style>
